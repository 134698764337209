<template>
  <TopicEditView disabled />
</template>

<script>
import TopicEditView from '@/views/topic/TopicEditView'

export default {
  name: 'TopicView',
  components: {
    TopicEditView
  }
}
</script>
