<script>
import NotifyService from '../../services/NotifyService'
import TopicNewView from './TopicNewView'

export default {
  name: 'TopicEditView',
  extends: TopicNewView,
  methods: {
    getTopic () {
      this.$store.dispatch('topic/fetchOne', this.$route.params.id)
        .then(() => {
          this.dataLoaded = true
          this.topic = this.$store.getters['topic/detail']
          this.topic.expanded.articleTypes.push(...this.getArticleTypes(this.topic.articleTypes))
          this.oldSlug = this.topic.slug
          this.oldSite = this.topic.site
          if (!this.$store.getters['user/hasPermissionForSite'](this.requiredPermissions.editRoute, this.topic.site)) {
            this.$router.push(`/topic/${this.topic.id}`)
          }
        })
    },
    async save () {
      this.$v.$touch()
      this.validateSlug()
        .then(() => {
          if (this.$v.$invalid || this.isSlugValid === false) {
            if (this.$v.$invalid) {
              NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
            }
            if (this.isSlugValid === false) {
              NotifyService.setErrorMessage(this.$t('topic.notify.slug_site_combination_must_be_unique'))
            }
            return
          }
          this.$store.dispatch('topic/update', this.prepareRequest(this.topic))
            .then(() => {
              if (this.$store.getters['topic/error'] === null) {
                NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
                this.oldSlug = this.topic.slug
                this.oldSite = this.topic.site
              } else {
                NotifyService.setErrorMessage(this.$store.getters['topic/error'])
              }
            })
            .catch(error => console.log(error))
        })
    }
  },
  created () {
    this.mode = 'update'
    this.$store.commit('topic/storeImage', null)
    this.$store.commit('topic/storeImageBg', null)
    this.getTopic()
  }
}
</script>
